
import React from 'react'

const Header = () => {
  return (
    
<header>
    <div id="header-fixed-height"></div>
    <div id="sticky-header" class="tg-header__area tg-header__area-three">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="tgmenu__wrap">
                        <div class="row align-items-center">
                            <div class="col-xl-5">
                                <div class="tgmenu__navbar-wrap tgmenu__main-menu d-none d-xl-flex">
                                    <ul class="navigation">
                                      
                                      
                                        <li><a href="/">Home</a></li>
                                        <li ><a href="/commerical">Commercial</a></li>
                                        <li className="menu-item-has-children"><a href="#">Social</a>
                                        <ul className="sub-menu">
                                        <li><a href="/home">Pet Social</a></li>
                                            {/* <li><a href="/chat">Chat</a></li> */}
                                            <li><a href="/discussiondashboard">Discussion</a></li>
                                            
                                        </ul>
                                    </li>
                                        <li><a href="/about">About Us</a></li>
                                    <li><a href="/contactus">Contact Us</a></li>
                                        {/* <li class="active"><a href="contact.html">contacts</a></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-2 col-md-4">
                                <div class="logo text-center">
                                    <a href="/"><img src="assets/img/logo/w_logo.png" alt="Logo"/></a>
                                </div>
                            </div>
                            <div class="col-xl-5 col-md-8">
                                <div class="tgmenu__action tgmenu__action-two d-none d-md-block">
                                    <ul class="list-wrap">
                                        <li class="header-search">
                                            <a href="javascript:void(0)" class="search-open-btn">
                                                <i class="flaticon-loupe"></i>
                                            </a>
                                        </li>
                                        <li class="header-cart">
                                            <a href="javascript:void(0)">
                                                <i class="flaticon-shopping-bag"></i>
                                                <span>0</span>
                                            </a>
                                        </li>
                                        <li class="header-btn login-btn"><a href="/signin" class="btn"><i
                                                    class="flaticon-locked"></i>Login</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="mobile-nav-toggler">
                            <i class="flaticon-layout"></i>
                        </div>

                        
                    </div>


                    <div class="tgmobile__menu">
                        <nav class="tgmobile__menu-box">
                            <div class="close-btn"><i class="fas fa-times"></i></div>
                            <div class="nav-logo">
                                <a href="/"><img src="https://api.petzoy.in/mainlogo/logo.png" alt="Logo"/></a>
                            </div>
                            <div class="tgmobile__search">
                                <form action="#">
                                    <input type="text" placeholder="Search here..."/>
                                    <button><i class="fas fa-search"></i></button>
                                </form>
                            </div>
                            <div class="tgmobile__menu-outer">
                              
                            </div>
                            <div class="social-links">
                                <ul class="list-wrap">
                                    <li><a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.whatsapp.com/" target="_blank"><i class="fab fa-whatsapp"></i></a></li>
                                    <li><a href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div class="tgmobile__menu-backdrop"></div>
                </div>
            </div>
        </div>
    </div>


    <div class="search__popup">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="search__wrapper">
                        <div class="search__close">
                            <button type="button" class="search-close-btn">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 1L1 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M1 1L17 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </button>
                        </div>
                        <div class="search__form">
                            <form action="#">
                                <div class="search__input">
                                    <input class="search-input-field" type="text" placeholder="Type keywords here"/>
                                    <span class="search-focus-border"></span>
                                    <button>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.55 18.1C14.272 18.1 18.1 14.272 18.1 9.55C18.1 4.82797 14.272 1 9.55 1C4.82797 1 1 4.82797 1 9.55C1 14.272 4.82797 18.1 9.55 18.1Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M19.0002 19.0002L17.2002 17.2002" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="search-popup-overlay"></div>
    

</header>

  )
}

export default Header